import React from "react"

import { ArrowDownOutlined } from "@ant-design/icons"
import ContentSectionCentered from "../../ContentSectionCentered/ContentSectionCentered"
import ServiceArticle from "../../ServiceArticle/ServiceArticle"
import beacon from "../../../images/beacon.svg"
import car from "../../../images/lamp.png"

import "./Services.scss"

const eccoText = "Výstražná a varovná světelná a zvuková technika různých barev a jejich kombinací pro hasiče, policii, sanitní vozy, stavební, zemědělská a speciální obslužná vozidla."
const vignalText = "Osvětlení pro nákladní a užitková vozidla. Zadní skupinové svítilny, poziční světla, osvětlení SPZ, přídavné pracovní světlomety, reflexní fólie a další speciální doplňky."

const eccoTags = [{ color: "#fc8900", text: "orange" }, { color: "#2b5fff", text: "blue" }, {
  color: "#ff0000",
  text: "red",
}, { color: "#ffffff", text: "white", textColor: "black" }, { color: "#1db400", text: "green" }]
const code3Tags = [{ color: "#2b5fff", text: "blue" }, { color: "#ff0000", text: "red" }, {
  color: "#fc8900",
  text: "orange",
}, { color: "#ffffff", text: "white", textColor: "black" }, { color: "#1db400", text: "green" }]
const vignalTags = [{ color: "#ff0000", text: "red" }, { color: "#ffffff", text: "white", textColor: "black" }, {
  color: "#fc8900",
  text: "orange",
}]

const Services = () =>
  <ContentSectionCentered id="zastoupeni" title="Zastoupení">
    <div className="app-section-services-wrapper">
      <ServiceArticle
        title="ECCO"
        imageUrl={beacon}
        description={eccoText}
        colorWrapperBackground="#edcd1f"
        colorTags={eccoTags}
        catalogueLink1="https://www.eccoesg.co.uk/gb/en/products/ProductSearch"
        catalogueLink2={{
          link: "https://downloads.ctfassets.net/d8q896v7fk3y/3UftDDyfDbegZLNn8MxOmA/a85842d1f0974ff75446e467fa3a0e18/Britax_Amber_Warning.pdf",
          text: "BRITAX",
        }}/>
      <ServiceArticle title="CODE3" imageUrl={beacon} description={eccoText} colorWrapperBackground="skyblue"
                      colorTags={code3Tags}
                      catalogueLink1="https://downloads.ctfassets.net/0j0u5f98aept/7mmUnHswoybJAwwq9vsK0I/8e553f44be33ca0e99f0647e8963f0f1/C3MASTER20__web_.pdf"
                      catalogueLink2={{
                        link: "http://premierhazard.co.uk/",
                        text: "PREMIER HAZARD",
                      }}/>
      <ServiceArticle title="VIGNAL LIGHTNING GROUP" imageUrl={car}
                      description={vignalText} colorWrapperBackground="tomato" colorTags={vignalTags}
                      catalogueLink1="https://www.vignal-group.com/media/Catalogue%20Product%20Pictures/Telechargement/CATALOGUES/VIGNAL_Catalogue2019_FRENALL%20-%20Optimise.pdf"/>
      <div className="app-section-services-contact-us">
        <p>Nenašli jste vámi požadovaný produkt? Chcete znát více detailů ? Zajímají Vás ceny? Máte zájem o vytvoření
          ucelené nabídky na základě Vašich přesných požadavků?</p>
        <p className="yellow" style={{ textTransform: "uppercase" }}>Neváhejte nás tedy kontaktovat !</p>
        <a className="arrow" href="#kontakty"><ArrowDownOutlined
          style={{ fontSize: "30px", margin: "auto", fontWeight: "bold", textAlign: "center", color: "tomato" }}/></a>
      </div>
    </div>
  </ContentSectionCentered>

export default Services
