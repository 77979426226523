import React from "react"
import { Slide } from "react-slideshow-image"

import lighthouse from "../../images/lighthouse-beacon-ss-1920.jpg"
import lamp from "../../images/lamp_slider.png"

import "./HeaderSlider.scss"

const slideImages = [
  lighthouse,
  lamp,
]

const properties = {
  height: 500,
  duration: 4000,
  transitionDuration: 500,
  infinite: true,
}

const HeaderSlider = () => <div className="slide-container">
  <Slide {...properties}>
    <div className="each-slide">
      <div style={{ "backgroundImage": `url(${slideImages[0]})` }}>
        <span style={{position: 'absolute', top: 0}}>Není maják....</span>
      </div>
    </div>
    <div className="each-slide">
      <div style={{ "backgroundImage": `url(${slideImages[1]})`, backgroundSize: 'cover' }}>
        <span style={{textAlign: 'right', color: '#333', position: 'absolute', top: '50px'}}>....Jako maják</span>
      </div>
    </div>
  </Slide>
  {/*<div className='content-wrapper'>*/}
  {/*  <div className='logo-wrapper'>*/}
  {/*    <img className='slider-image' src={beacon} alt="beacon"/>*/}
  {/*    <h1>SPECIALIST IN AUTOMOTIVE LAMPS AND BEACONS</h1>*/}
  {/*    <Button className='gradient-border' type='primary' size="large" style={{backgroundColor: "transparent", }}>LEARN MORE</Button>*/}
  {/*  </div>*/}
  {/*</div>*/}
</div>

export default HeaderSlider
