import React from 'react';
import PropTypes from 'prop-types';

import './ContentTitle.scss';

const propsTypes = {
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string
};

const defaultProps = {
  titleColor: '#8b8b8b'
};

const ContentTitle = (props) => {
  const {title, titleColor} = props;

  return <div className='content-title'>
    <h1 style={{color: titleColor}}>{title}</h1>
  </div>;
};

ContentTitle.defaultProps = defaultProps;
ContentTitle.propTypes = propsTypes;

export default ContentTitle;
