import React from "react"

import ContentSectionCentered from "../../ContentSectionCentered/ContentSectionCentered"

import "./About.scss"

const About = () => <ContentSectionCentered id='onas' title='O nás' backgroundColor='#f7f7f7'>
  <div className='app-section-about-wrapper'>
    <p className='app-section-about-description'>
      Firma RADWES vznikla v roce 2018 a plynule navazuje na aftermarketové aktivity společnosti KES Vratimov a tradici,
      které trvá nepřetržitě od roku 1992. Zaměřením firmy je především obchodní a marketingové zastupování významných
      zahraničních výrobců autopříslušenství
      a to především světelného vybavení a speciální výstražné světelné a zvukové výbavy vozidel záchranných služeb,
      hasičských
      sborů, složek policie, celní správy a podobně. V nabídce je možno najít majákové rampy, majáky, blikače, jak v
      modré, tak i oranžové, červené a dalších barev. Dále nabízíme sirény, reproduktory, sváděcí aleje a další
      související sortiment.
      Rovněž se zaměřujeme na světelnou výbavu pro nákladní a užitkové automobily, jako jsou světlomety, svítilny,
      poziční světla, pracovní světlomety blinkry, zpětná zrcátka a další vybavení. Společnost RADWES zastupuje na českém a slovenském
      trhu následující výrobce: <span className='bold'>VIGNAL LIGHTNING GROUP, ESG (ECCO, BRITAX, PREMIER HAZARD, CODE3, VISION ALERT), ZKW, SARR</span> a další.
    </p>
  </div>
</ContentSectionCentered>

export default About
