/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Layout } from "antd"

import HeaderMenu from "./Menu/HeaderMenu"
import HeaderSlider from "../components/HeaderSlider/HeaderSlider"
import Services from "../components/pages/Services/Services"
import About from "../components/pages/About/About"
import Numbers from "../components/pages/Numbers/Numbers"
import References from "../components/pages/References/References"
import Clients from "../components/pages/Clients/Clients"
import Contact from "../components/pages/Contact/Contact"
import Footer from "../components/Footer/Footer"

import "antd/dist/antd.css"
import "./layout.scss"
import { Link } from "gatsby"

import radwesLogo from "../images/radwes_logo.png"

const { Header, Content } = Layout

const PageLayout = () => {
  return (
    <>
      <Layout>
        <Header
          style={{ position: "fixed", zIndex: 1, width: "100%", height: 100, padding: 20, background: "#575759" }}>
          {/*<div style={{ width: "50px", float: "left", fontSize: "2rem", fontWeight: "bold", color: "#f4e255" }}>RADWES*/}
          {/*</div>*/}
          <div className='app-header-logo' style={{float: 'left'}}>
            <Link to="/">
              <img src={radwesLogo} alt='radwes_logo' style={{ width: "350px" }}/>
            </Link>
          </div>
          <HeaderMenu/>
        </Header>
        <Content className="slider-layout" style={{ padding: "0", marginTop: 100 }}>
          <HeaderSlider/>
        </Content>
        <Content className="site-layout" style={{ padding: "0" }}>
          <div className="site-layout-background" style={{ padding: 0, minHeight: 380 }}>
            <About/>
            <Numbers/>
            <Clients/>
            <Services/>
            <References/>
            <Contact/>
          </div>
        </Content>
        <Footer/>
      </Layout>
    </>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout
