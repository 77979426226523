import React from 'react';

import './Footer.scss';

const Footer = () => <div className='app-footer'>
  <div className='app-footer-section-wrapper'>
    <ul>
      <li>Škrobálkova 200/42</li>
      <li>718 00 Ostrava-Kunčičky</li>
      <li>Česká republika</li>
    </ul>
    <ul>
      <li>IČ: 06974449</li>
      <li>DIČ: CZ06974449</li>
      <li>mail: info@radwes.cz</li>
    </ul>
  </div>
</div>;

export default Footer;
