import React from "react"
import PropTypes from "prop-types"

import "./SericeArticle.scss"
import { Button, Tag } from "antd"

const propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  colorWrapperBackground: PropTypes.string.isRequired,
  colorTags: PropTypes.array,
  catalogueLink1: PropTypes.string.isRequired,
  catalogueLink2: PropTypes.string,
}

const ServiceArticle = (props) => {
  const { title, imageUrl, description, colorWrapperBackground, colorTags, catalogueLink1, catalogueLink2 } = props

  return <div className='app-section-service-article'>
    <h4>{title}</h4>
    <div className='app-section-service-article-image' style={{ backgroundColor: colorWrapperBackground }}>
      <img src={imageUrl} alt={`Tohle Je Obrazek - ${title}`}/>
    </div>
    <div className='app-section-service-article-colors' style={{ backgroundColor: "#666" }}>
      {colorTags.map((tag) => {
        return <Tag color={tag.color} style={{ padding: "8px 10px" }}/>
      })}
    </div>
    <div className='app-section-service-article-content'>
      <p>{description}</p>
      <Button type="link"
              target='_blank'
              href={catalogueLink1}><span style={{ textDecoration: "underline" }}>Produkty {title}</span></Button>
      {catalogueLink2 && <Button style={{ width: "100%", margin: "20px 0" }}
                                 type="link"
                                 target='_blank'
                                 href={catalogueLink2.link}><span
        style={{ textDecoration: "underline" }}>Produkty {catalogueLink2.text}</span></Button>}
    </div>
  </div>
}

ServiceArticle.propTypes = propTypes

export default ServiceArticle
