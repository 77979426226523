import React from "react"

import "./Clients.scss"
import ContentSectionCentered from "../../ContentSectionCentered/ContentSectionCentered"

const Clients = () => <ContentSectionCentered id="sluzby" title='Služby' backgroundColor={'rgb(219,219,219)'}>
  <div className='app-section-clients-wrapper'>
    <div className='app-section-clients-description'>
      <p>Naší snahou je věnovat zákazníkům maximální pozornost a vycházet jim co nejvíce vstříc.</p>
      <ul>
        <li>provádíme nabídková řízení dle přání zákazníka a zadavatele.</li>
        <li>prezentujeme naše zboží při odborných a propagačních akcích.</li>
        <li>dodávky zboží realizujeme tak, aby co nejlépe vyhovovaly našim zákazníkům a to ve spolupráci se
          specializovanými přepravními službami - PPL, FOFR, Česká pošta a další.
        </li>
      </ul>
    </div>
  </div>
</ContentSectionCentered>

export default Clients
