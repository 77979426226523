import React from "react"

import ContentSectionCentered from "../../ContentSectionCentered/ContentSectionCentered"

import "./Contact.scss"
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined"
import PhoneOutlined from "@ant-design/icons/lib/icons/PhoneOutlined"

const Contact = () => <ContentSectionCentered id='kontakty' title='Kontakty' backgroundColor='white'>
  <div className='app-contact-section'>
    <div className='column'>
      <h3>Obecné informace</h3>
      <p><PhoneOutlined rotate='90' /> +420 603 236 808</p>
      <p><MailOutlined /> info@radwes.cz</p>
    </div>
    <div className='column'>
      <h3>Obchodní informace</h3>
      <p><PhoneOutlined rotate='90' /> +420 603 236 808</p>
      <p><MailOutlined /> p.wessely@radwes.cz</p>
    </div>
   <div className='column'>
     <h3>Expedice</h3>
     <p><PhoneOutlined rotate='90'/> +420 728 475 839</p>
     <p><MailOutlined /> s.senkyrik@radwes.cz</p>
   </div>
  </div>
</ContentSectionCentered>

export default Contact
