import React from 'react';
import PropTypes from 'prop-types';

import ContentTitle from '../ContentTitle/ContentTitle';

import './ContentSectionCentered.scss';

const propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  backgroundColor: PropTypes.string
};

const defaultProps = {
  backgroundColor: '#ffffff'
};

const ContentSectionCentered = (props) => {
  const {id, title, backgroundColor, titleColor} = props;

  return <div id={id} className='content-section-centered' style={{backgroundColor}}>
    <ContentTitle title={title} titleColor={titleColor}/>
    {props.children}
  </div>
};

ContentSectionCentered.defaultProps = defaultProps;
ContentSectionCentered.propTypes = propTypes;

export default ContentSectionCentered;
