import React from 'react';

import './Numbers.scss';

const currentDate = new Date().getFullYear();
const creationDate = 1992;

const data = {
  experience: {
    number: currentDate - creationDate,
    text: 'let zkušeností'
  },
  customers: {
    number: 1158,
    text: 'spokojených zákazníků'
  },
  offers: {
    number: 5680,
    text: 'druhů zboží v nabídce'
  }
}

const Numbers = () => <div className='app-section-numbers-wrapper'>
  <div className='row'>
    <div className='col'>
      <h3>{data.experience.number}</h3>
      <span className='title'>{data.experience.text}</span>
    </div>
    <div className='col'>
      <h3>{data.customers.number}</h3>
      <span className='title'>{data.customers.text}</span>
    </div>
    <div className='col'>
      <h3>{data.offers.number}</h3>
      <span className='title'>{data.offers.text}</span>
    </div>
  </div>
</div>;

export default Numbers;
