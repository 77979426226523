import React from "react"
import { Menu } from "antd"

import "./Menu.scss"

const { SubMenu } = Menu

const handleClick = e => {
  console.log("click ", e)
}

const HeaderMenu = () => {
  return (
    <Menu className='app-header-menu' onClick={handleClick} mode="horizontal" theme={"dark"}
          style={{ background: "#575759" }}
          style={{ float: "right", textTransform: "uppercase", fontWeight: "bold" }}>
      <Menu.Item key="aktuality">
        <a href="#aktuality" target="_self" rel="noopener noreferrer">
          Aktuality
        </a>
      </Menu.Item>
      <Menu.Item key="onas">
        <a href="#onas" target="_self" rel="noopener noreferrer">
          O nás
        </a>
      </Menu.Item>
      <Menu.Item key="sluzby">
        <a href="#sluzby" target="_self" rel="noopener noreferrer">
          Služby
        </a>
      </Menu.Item>
      <Menu.Item key="zastoupeni">
        <a href="#zastoupeni" target="_self" rel="noopener noreferrer">
          Zastoupení
        </a>
      </Menu.Item>
      <Menu.Item key="reference">
        <a href="#reference" target="_self" rel="noopener noreferrer">
          Reference
        </a>
      </Menu.Item>
      <SubMenu title="Katalogy">
        <Menu.Item key="setting:1">
          <a href='https://www.eccoesg.co.uk/gb/en/products/ProductSearch'
             target='_blank' rel="noopener noreferrer">ECCO</a>
        </Menu.Item>
        <Menu.Item key="setting:2">
          <a href='https://assets.ctfassets.net/d8q896v7fk3y/5PWgwZSlDLdiqALh8Ihj84/23e8d8ad6617258f978b97f267f8449f/12__Series_Brochure_ECCO_A4_ENGLISH_Updated_MedRes_Digital.pdf'
             target='_blank' rel="noopener noreferrer">ECCO rampy 12+Series</a>
        </Menu.Item>
        <Menu.Item key="setting:3">
          <a href='https://assets.ctfassets.net/d8q896v7fk3y/6gsqpOtbqSu8vQYjBeNM2M/b478d55d7dfcfb0d1d04fd731b1be0f3/13_Series_Leaflet__NEW_Centre_Illuminiation_A4.pdf'
             target='_blank' rel="noopener noreferrer">ECCO rampy 13+Series</a>
        </Menu.Item>
        <Menu.Item key="setting:4">
          <a href='https://downloads.ctfassets.net/d8q896v7fk3y/37rAV4BQ4RS3mm4NOiKpzs/3e9704617ab69fe52fbf1c60e0ae4c06/ECCO_Catalogue_Directionals_MedRes_Digital.pdf'
             target='_blank' rel="noopener noreferrer">ECCO LED výstražná světla</a>
        </Menu.Item>
        <Menu.Item key="setting:5">
          <a href='https://assets.ctfassets.net/d8q896v7fk3y/5Hql6bihNYWe6YIQYsGgcE/d3d041ca681c698c3bca8abfabc80705/Interior_Lighting_Brochure.pdf'
             target='_blank' rel="noopener noreferrer">ECCO vnitřní osvětlení</a>
        </Menu.Item>
        <Menu.Item key="setting:6">
          <a href='https://downloads.ctfassets.net/d8q896v7fk3y/3UftDDyfDbegZLNn8MxOmA/a85842d1f0974ff75446e467fa3a0e18/Britax_Amber_Warning.pdf'
             target='_blank' rel="noopener noreferrer">BRITAX majáky</a>
        </Menu.Item>
        <Menu.Item key="setting:7">
          <a href='https://assets.ctfassets.net/d8q896v7fk3y/74TVQ3AQeD3CneLur5Wibs/a40e45269972c5a23a9c24046eb3b777/Britax_Lightbars.pdf'
             target='_blank' rel="noopener noreferrer">BRITAX rampy</a>
        </Menu.Item>
        <Menu.Item key="setting:7">
          <a href='https://downloads.ctfassets.net/d8q896v7fk3y/2dDTJ7fB1k3Kj1m2PzelsI/06f1593d11116ba4be2cde5e6fb7829d/Britax_Lamps.pdf'
             target='_blank' rel="noopener noreferrer">BRITAX svítilny</a>
        </Menu.Item>
        <Menu.Item key="setting:8">
          <a href='https://assets.ctfassets.net/d8q896v7fk3y/7q3k3B3ZPbuhdEoyAYaZZx/551461d18ae6fec53410d2de04ee0e93/Britax_Mirrors_Brochure_v2_2016.pdf'
             target='_blank' rel="noopener noreferrer">BRITAX zpětná zrcátka</a>
        </Menu.Item>
        <Menu.Item key="setting:9"><a
          href='https://downloads.ctfassets.net/0j0u5f98aept/7mmUnHswoybJAwwq9vsK0I/8e553f44be33ca0e99f0647e8963f0f1/C3MASTER20__web_.pdf'
          target='_blank' rel="noopener noreferrer">CODE3</a></Menu.Item>
        <Menu.Item key="setting:10"><a href='http://premierhazard.co.uk/' target='_blank' rel="noopener noreferrer">PREMIER
          HAZARD</a></Menu.Item>
        <Menu.Item key="setting:11"><a
          href='https://www.vignal-group.com/media/Catalogue%20Product%20Pictures/Telechargement/CATALOGUES/VIGNAL_Catalogue2019_FRENALL%20-%20Optimise.pdf'
          target='_blank' rel="noopener noreferrer">VIGNAL</a></Menu.Item>
      </SubMenu>
      <Menu.Item key="kontakty">
        <a href="#kontakty" target="_self" rel="noopener noreferrer">
          Kontakty
        </a>
      </Menu.Item>
    </Menu>
  )
}

export default HeaderMenu
