import React from "react"

import ContentSectionCentered from "../../ContentSectionCentered/ContentSectionCentered"
import data from "./references.json"

import "./References.scss"

const References = () => <ContentSectionCentered id="reference" title='Reference' backgroundColor='rgb(219,219,219)'>
  <div className='app-section-references-wrapper'>
    <p className='app-section-references-description'>Mezi naše zákazníky patří jak výrobci nákladních a speciálních vozidel, přestavbaři a specializované velkoobchody, tak koncoví uživatelé.</p>
    <ul>
      {data.references.map((ref) =>
        <li>{ref.name}</li>,
      )}
    </ul>
  </div>
</ContentSectionCentered>

export default References
